export const config = {
  "label": "dev",
  "ModuleFE_Thunderstorm": {
    "appName": "app-advisor-frontend - (dev)"
  },
  "ModuleFE_XHR": {
    "origin": "https://advisor-backend.dev.quai.md",
    "timeout": 30000,
    "compress": false,
    "minLogLevel": false
  },
  "ModuleFE_FirebaseListener": {
    "firebaseConfig": {
      "apiKey": "AIzaSyDZWTxPBtyq9Kh5dky9f0Ks-CiiOg3luic",
      "authDomain": "quai-md-runtime-dev.firebaseapp.com",
      "databaseURL": "https://quai-md-runtime-dev-default-rtdb.firebaseio.com",
      "projectId": "quai-md-runtime-dev",
      "storageBucket": "quai-md-runtime-dev.appspot.com",
      "messagingSenderId": "641435297260",
      "appId": "1:641435297260:web:f3671112154cc0520f8cc9",
      "measurementId": "G-3J8TGPS7EM"
    }
  }
};